<template>
  <section class="news">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <section class="title">
        <h1><strong>{{ titleObj.title }}</strong></h1>
        <p>{{ titleObj.content }}</p>
        <el-image :src="titleObj.imgUrl" style="width: 8rem;"></el-image>
      </section>
      <el-row :gutter="30">
        <el-col :span="12" v-for="(item, index) in newsData" :key="index">
          <div class="content">
            <el-image :src="item.imgUrl"></el-image>
            <p><strong>{{ item.title }}</strong></p>
            <p>{{ item.content }}</p>
            <p><router-link class="link" :to="{path: item.url, query: {id: item.id}}">更多<i class="el-icon-right"></i></router-link></p>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
export default {
  name: 'news',
  components: {
    imageBanner,
  },
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/新闻资讯.png')
      },
      titleObj: {
        title: '新闻资讯',
        content: '了解旋荣最新动态，掌握行业发展动向',
        imgUrl: require('../../assets/images/2023/news/配图.png'),
      },
      newsData: [
        {imgUrl: require('../../assets/images/2023/news/01.png'), title: '旋荣新闻', content: '立足行业前沿，展现旋荣风采……', url: '/newsCategory', id: 1},
        {imgUrl: require('../../assets/images/2023/news/02.png'), title: '行业新闻', content: '解析央企电商采购的特点、采购形式，了解央企电商采购的历史变革所带来的挑战和机遇。', url: '/newsCategory', id: 2},
      ]
    }
  }
}
</script>

<style scoped>
.news {background-color: #ecf0f5; padding-bottom: 1rem;}
.container p {margin: 1rem 0;}
.container .title {text-align: center; line-height: 2;}
.container .title  strong { font-size: 2rem; border-bottom: 2px solid #ccc; padding: .6rem 0; line-height: 2;}
.content strong { font-size: 1.2rem; border-bottom: 4px solid #e67700; padding: .6rem 0; line-height: 2;}
.content .link {color: #e67700}
</style>